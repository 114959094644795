.options-container {
    .btn {
        margin: 20px;
        height: 55px;
        width: 300px;
        background: transparent;
        color: white;
        border: 2px solid #ffffff;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        transition: all 0.3s ease 0s;
        outline: none;
    }
    .btn:hover {
        cursor: pointer;
    }
    .btn:active {
        background-color: black;
        -webkit-box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.7);
        -moz-box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.7);
        box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.7);
    }
}