.contact-container {
    .contact-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 15px;
        margin-bottom: 20px;
        .contact-category {
            font-weight: 500;
            width: 150px;
        }
        .contact-information {
            min-width: 150px;
            overflow-wrap: break-word;
            color: grey;
        }
    }
}


@media only screen and (max-width: 1200px) {
    .contact-container {
        .contact-item {
            .contact-category {
                width: 150px;
            }
            .contact-information {
                width: 150px;
                overflow-wrap: break-word;
                color: grey;
            }
        }
    }
}
