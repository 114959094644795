body {
  margin: 0;
  font-family: 'Archivo Black', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(45deg, #0f0f0f 8.33%, #1a1a1a 8.33%, #1a1a1a 25%, #000000 25%, #000000 50%, #0f0f0f 50%, #0f0f0f 58.33%, #1a1a1a 58.33%, #1a1a1a 75%, #000000 75%, #000000 100%);
  background-size: 42.43px 42.43px;
  color: white;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

