.logo-container {
    font-family: 'Roboto', 'Archivo Black', sans-serif;
    font-size: 25px;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    
    .logo-text {
        margin-right: 8px;
    }:hover {
        cursor: pointer;
    }

    svg {
        margin-top: 1px;
    }
}