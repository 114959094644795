.profile-container {
    .image-container {
        overflow: hidden;
        margin-bottom: 24px;
        overflow: hidden;
        .img-responsive {
            overflow: hidden;
            height: 400px;
            width: 400px;
        }
    }
    .tag-container {
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .text {
            font-size: 12px;
            font-weight: 600;
            margin-right: 8px;
        }
    }
}


@media only screen and  (min-width: 376px) and (max-width: 425px) {
    .profile-container {
        .image-container {
            margin-bottom: 24px;
            .img-responsive {
                height: 280px;
                width: 280px;
            }
        }
    }
}

@media only screen and  (min-width: 320px) and (max-width: 375px) {
    .profile-container {
        .image-container {
            height: 275px;
            width: 275px;
            .img-responsive {
                height: 275px;
                width: 275px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .profile-container {
        .image-container {
            height: 260px;
            width: 260px;
            .img-responsive {
                height: 260px;
                width: 260px;
            }
        }
    }
}