.hire-me-container {
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    background: white;
    color: black;    
    font-family: 'Roboto', sans-serif;
    -webkit-box-shadow: 10px 0px 47px 47px rgba(0,0,0,1);
    -moz-box-shadow: 10px 0px 47px 47px rgba(0,0,0,1);
    box-shadow: 10px 0px 47px 47px rgba(0,0,0,0.8);
    .header {
        padding: 40px 40px 0px 40px;

        .title {
            font-weight: 500;
            font-size: 54px;
        }
        .subtitle {
            font-weight: 400;
            font-size: 16px;
            margin-top: 4px;
        }
        .request-error {
            background-color: #f44336;
            border: 2px solid #f44336;
            border-radius: 5px;
            margin-top: 10px;
            font-weight: 600;
            color: white;
            text-align: center;
            padding: 10px
        }
        .request-success {
            background-color: #4caf50;
            border: 2px solid #4caf50;
            border-radius: 5px;
            margin-top: 10px;
            font-weight: 600;
            color: white;
            text-align: center;
            padding: 10px 
        }
    }
    form {
        background: white;
        padding: 20px 40px 60px 40px;
        input,
        textarea {
            font-family: inherit;
            padding: 5px 5px;
            width: 100%;
            margin-top: 3px;
            margin-bottom: 15px;
            box-sizing: border-box;
            min-height: 40px;
            max-height: 200px;
            min-width: 100%;
            max-width: 100%;
        }

        .btn {
            border: 2px solid rgba(0,0,0,1);
            padding: 10px 35px;
            font-weight: 600;
            font-size: 16px;
            color: black;
            background-color: white;
            transition: all 0.3s ease 0s;
            outline: none;
        }
        .btn:hover {
            cursor: pointer;
        }
        .btn:active {
            background-color: black;
            -webkit-box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.2);
            box-shadow: 0px 10px 20px 10px rgba(0,0,0,0.2);
        }
        .error {
            color: red;
        }
        label {
            color: black;
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 2px;
            display: block;
        }
    }
}

@media only screen and (min-width: 426px) and (max-width: 933px) {
    .hire-me-container {
        .header {
            .title {
                font-weight: 500;
                font-size: 30px;            
            }
            .subtitle {
                font-weight: 400;
                font-size: 14px;
                margin-top: 4px;
            }
        }
        form {
            textarea {
                max-height: 150px;
            }
        }
    }
  }

  @media only screen and  (min-width: 376px) and (max-width: 425px) {
    .hire-me-container {
        .header {
            .title {
                font-weight: 500;
                font-size: 30px;            
            }
            .subtitle {
                font-weight: 400;
                font-size: 14px;
                margin-top: 4px;
            }
        }
        form {
            textarea {
                max-height: 100px;
            }
        }
    }
  }

  
@media only screen and  (min-width: 321px) and (max-width: 375px) {
    .hire-me-container {
        .header {
            .title {
                font-weight: 500;
                font-size: 30px;            
            }
            .subtitle {
                font-weight: 400;
                font-size: 14px;
                margin-top: 4px;
            }
        }
        form {
            textarea {
                max-height: 100px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .hire-me-container {
        .header {
            .title {
                font-weight: 500;
                font-size: 30px;            
            }
            .subtitle {
                font-weight: 400;
                font-size: 14px;
                margin-top: 4px;
            }
        }
        form {
            textarea {
                max-height: 100px;
            }
        }
    }
}
