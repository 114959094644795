.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    .header-left {
        height: 30px;
        flex: 1;
    }

    .header-right {
        height: 30px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}