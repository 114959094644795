.welcome-message-container {
    .title {
        .title-upper {
            font-weight: 500;
            font-size: 54px;
        }
    }
    .subtitle {
        font-weight: 400;
        font-size: 14px;
        margin-top: 4px;
    }
}

@media only screen and (max-width: 425px) {
    .welcome-message-container {
        .title {
            .title-upper {
                font-weight: 500;
                font-size: 30px;
            }
        }
        .subtitle {
            font-weight: 400;
            font-size: 14px;
            margin-top: 4px;
        }
    }
}