.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 20px 40px 20px 40px;
    color: black;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    -webkit-box-shadow: 0px 0px 47px 47px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 47px 47px rgba(0,0,0,1);
    box-shadow: 0px 0px 47px 47px rgba(0,0,0,0.8);

    .card-side {
        display: flex;
        justify-content: center;
        width: 400px;
        margin-right: 20px;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 40px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        flex: 2;
        margin-right: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 20px;

        .card-top {
            border-bottom: 1px solid lightgrey;
            flex: 1;
            padding-bottom: 20px;
        }
        .card-bottom {
            padding-top: 20px;
            flex: 3;
        }
    }
}

@media only screen and (min-width: 426px) and (max-width: 933px) {
    .card-container {
        width: 480px;
        .card-side {
            margin-right:40px;
            margin-bottom: 0px;
        }
        .card-body {
            margin-top: 0px;
            margin-left: 40px;
        }
    }
  }

  @media only screen and  (min-width: 376px) and (max-width: 425px) {
    .card-container {
        width: 360px;
        .card-side {
            margin-right:40px;
            margin-bottom: 0px;
        }
        .card-body {
            margin-top: 0px;
            margin-left: 40px;
        }
    }
  }

  
@media only screen and  (min-width: 321px) and (max-width: 375px) {
    .card-container {
        width: 360px;
        .card-side {
            margin: 40px 40px 0px 40px;
        }
        .card-body {
            margin: 0px 40px 40px 40px;
        }
    }
}

@media only screen and (max-width: 320px) {
    .card-container {
        width: 300px;
        .card-side {
            margin: 20px 20px 0px 20px;
        }
        .card-body {
            margin: 0px 20px 20px 20px;
        }
    }
}
