.layout-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .layout-top {
        padding: 30px;
    }
    .layout-bottom {
        flex: 1;
        padding: 30px;
        padding-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 375px) {
    .layout-container {
        .layout-top {
            padding: 30px 10px 30px 10px;
        }
        .layout-bottom {

        }
    }
}
